<template>
    <div>
        <Table class="table_style" stripe border :loading="loading" :columns="columns" :data="withdrawData"
               @on-selection-change="updateSelection"></Table>
        <Button type="primary" size="large" @click="approveWithdraw">approve withdraw</Button>
    </div>
</template>

<script>
    export default {
        name: "withdrawInfo",
        data() {
            return {
                withdrawData: [],
                selectedData: [],
                loading: false,
                count: 0,
                columns: [
                    {
                        type: 'selection',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: 'id',
                        key: 'id',
                    },
                    {
                        title: 'withdrawUser',
                        key: 'withdrawUser'
                    },
                    {
                        title: 'total(USD)',
                        key: 'total'
                    },
                    {
                        title: 'email',
                        key: 'withdrawEmail'
                    },
                    {
                        title: 'state',
                        key: 'state',
                        sortable: true
                    },
                    {
                        title: 'createTime',
                        key: 'createTime',
                        sortable: true
                    },
                    {
                        title: 'updateTime',
                        key: 'updateTime',
                        sortable: true
                    },
                ],
            }
        },
        methods: {
            getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i].trim();
                    if (c.indexOf(name) !== -1) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            getAllWithdrawData() {
                let Tself = this;
                let user = this.getCookie("user_id");

                this.withdrawData = [];
                this.loading = true;
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'get',
                    url: '/payment/find/all?number=0&size=1000&user='+user,
                }).then((response) => {
                    let data = response.data.data.items;
                    Tself.count = data.content.length;
                    for (var i = 0; i < data.content.length; i++) {
                        if (data.content[i].state === "approved") {
                            Tself.withdrawData.push({
                                id: data.content[i].id,
                                withdrawUser: data.content[i].withdrawUser,
                                currency: data.content[i].currency,
                                total: data.content[i].total,
                                withdrawEmail: data.content[i].withdrawEmail,
                                state: data.content[i].state,
                                createTime: data.content[i].createTime,
                                updateTime: data.content[i].updateTime,
                                _disabled: true
                            })
                        } else {
                            Tself.withdrawData.push({
                                id: data.content[i].id,
                                withdrawUser: data.content[i].withdrawUser,
                                currency: data.content[i].currency,
                                total: data.content[i].total,
                                withdrawEmail: data.content[i].withdrawEmail,
                                state: data.content[i].state,
                                createTime: data.content[i].createTime,
                                updateTime: data.content[i].updateTime,
                            })
                        }
                    }
                    Tself.loading = false;
                }).catch((error) => {
                    if (error.response) {
                        if(error.response.data.data.items){
                            this.$Message.error(error.response.data.data.items)
                        }
                    }else {
                        this.$Message.error(error.message);
                    }
                    Tself.loading = false;
                })

            },
            approveWithdraw() {
                let Tself = this;
                if (!this.selectedData.length > 0) {
                    this.$Message.error("You should select something")
                    return
                }
                console.log(this.selectedData)
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/payment/approveWithdraw',
                    data: this.selectedData,
                }).then(function (response) {
                    let data = response.data.data.items;
                    if (data === "approve withdraw success") {
                        Tself.$Message.success("Approve withdraw success");

                    } else {
                        Tself.$Message.error(data);
                    }
                    Tself.getAllWithdrawData();
                }).catch(function (error) {
                    console.log(error);
                });
            },
            updateSelection(selection) {
                this.selectedData = selection
            }
        },
        mounted() {
            this.getAllWithdrawData();
        },
        created() {
        }
    }
</script>

<style scoped>
    .table_style {
        margin: 10px 10px;

    }


</style>